<template>
  <div class="main">
    <el-form :model="form"
             class="form_warp"
             ref="formRef"
             label-width="60px">
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item label="科目:"
                        prop="subject_id">
            <el-select v-model="form.subject_id"
                       style="width:100%"
                       placeholder="请选择">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="审核状态:"
                       label-width="90px"
                        prop="status">
            <el-select v-model="form.has_check"
                       clearable
                       style="width:100%"
                       placeholder="请选择">
              <el-option v-for="item in statusList"
                         :key="item.id"
                         :label="item.status_name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-button type="primary"
                     @click="search">
            提交
          </el-button>
        </el-col>
      </el-row>
    </el-form>
    <Table ref="TablesRef"
           :showSelection="false"
           :tableData="tableData" />

    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />

  </div>
</template>

<script>
import { saveAs } from "file-saver"
import { printPdf } from '@/api/dataAnalysis.js'
export default {
  name: 'record',
  data () {
    return {
      form: {

        page: 1,
        limit: 10
      },
      subjectList: [],
      tableData: [],
      page: 1,
      size: 10,
      totalElements: 0,
      statusList: [
        { id: -1, status_name: '全部' },
        { id: 0, status_name: '未审核' },
        { id: 1, status_name: '已审核' },
      ],
      tableColumns: [
        { prop: 'id', align: 'left', label: '查重ID', width: '' },
        { prop: 'question_id1', align: 'left', label: '题目ID1', width: '' },
        { prop: 'question_id2', align: 'left', label: '题目ID2', width: '' },
        { prop: 'dup_index', align: 'left', label: '状态', width: '', isStatus: true },

        {
          prop: 'operation', align: 'left', label: '操作', width: '280', type: 'text', btns: [
            { content: '审核', class: 'text_btn_primary', event: 'toCheck', isChangeText: true },

            // { content: '删除', color: 'red', event: 'deleteInfo' },
          ]
        },
      ]
    }
  },
  mounted () {
    this.setTablesColums()
    this.getSubject()
  },
  methods: {
    getSubject () {
      this.$http({
        url: '/api/v1/public/teacher_subject_lst',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.list
      })
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    getText (prop, value, row) {
      console.log('888', prop, value, row);

      if (prop == "operation") {
        if (row['dup_index'] == 0.5) {
          return '审核'
        } else {
          return '查看'
        }
      }
    },

    getClass (prop, value, row) {
      if (prop == 'dup_index') {
        console.log()
        // return value == 0 ? 'pass' : 'normal_status'
        if (value == 0.5) {
          return 'unload'
        } else {
          return 'ypg'
        }
      }


    },
    getValue (prop, value, row) {

      if (prop == 'dup_index') {
        if (value == 0.5) {
          return '未审核'
        } else {


          if (row.dup_index == 1) {
            return '已审核:重复'
          } else if (row.dup_index == 0) {
            return '已审核:不重复'
          }


        }
      }
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    toCheck (row) {


      this.$router.push(
        {
          path: '/examinationEntry/toCheck',
          query: {
            qusInfo: JSON.stringify(row),
          }
        })


    },
    async exportBtn (row) {
      const data = await printPdf({ user_paper_id: row.user_paper_id })
      let blob = new Blob([data], { type: 'application/json;charset=utf-8' });

      saveAs(blob, `${row.user_paper_id}试卷.pdf`)
    },
    initData (page, limit) {
      this.form.page = page
      this.form.limit = limit
      this.form.check_user_id = 1
      this.$http({
        url: '/api/v1/question/dup_list',
        method: 'get',
        params: this.form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    deleteInfo (row) {
      console.log('row', row)
      console.log('b', this.rowDetectionId)
      this.$confirm("确定将其删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {

        this.$http({

          url: '/api/v1/tang/del_paper',
          method: 'post',
          data: {
            detection_id: this.$route.query.detection_id || window.localStorage.getItem('detection_id'),
            user_paper_id: row.user_paper_id,
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.refresh()
        })

      })
    },
    getSubject () {
      this.$http({
        url: '/api/v1/papers/subject',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.subject
      })
    },
    postInfo () {
      console.log('this', this.detection_id)
      this.$http({

        url: '/api/v1/tang/del_paper',
        method: 'post',
        params: {
          detection_id: this.$route.query.detection_id || window.localStorage.getItem('detection_id'),
          user_paper_id: row.user_paper_id,
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '删除成功!',
          type: 'success'
        });
        this.refresh()
      })

    },
    toGroupTest () {
      let id = window.localStorage.getItem('detection_id')
      this.$router.push(
        {
          path: '/dataAnalysis/viewTestPaper/groupTest',
          query: {
            detection_id: id,
            user_paper_id: window.localStorage.getItem('user_paper_id')
          }
        })
    },
    toAllTest () {
      let id = window.localStorage.getItem('detection_id')
      this.$router.push(
        {
          path: '/dataAnalysis/viewTestPaper/allTest',
          query: {
            detection_id: id,
            user_paper_id: window.localStorage.getItem('user_paper_id')
          }
        })
    },
    toTeacherPaper () {
      let id = window.localStorage.getItem('detection_id')
      this.$router.push(
        {
          path: '/dataAnalysis/viewTestPaper/teacherPaper',
          query: {
            detection_id: id,
            user_paper_id: window.localStorage.getItem('user_paper_id')
          }
        })
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    async checkInfo (row) {
      // let id = window.localStorage.getItem('detection_id')
      window.localStorage.setItem('template_name', row.template_name)
      window.localStorage.setItem('subject_name', row.subject_name)
      this.$router.push(
        {
          path: '/dataAnalysis/viewTestPaper/view',
          query: {
            user_paper_id: row.user_paper_id,
            subject_name: row.subject_name,
            template_name: row.template_name
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  padding: 12px 12px 0 12px;
  background: white;
  font-family: Regular;
}
::v-deep .el-row {
  margin-bottom: 16px;
}
::v-deep .el-button {
  padding: 0;
  width: 88px;
  height: 32px;
}
.pedding {
  color: green;
}
::v-deep .opertion_image_warp .el-button--mini {
  border: none;
  width: 86px;
  height: 32px;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  background: none;
  font-size: 14px;
}
::v-deep .opertion_image_warp:nth-of-type(4) {
  /* 在这里编写你要应用的样式 */
  span {
    color: red;
  }
}

::v-deep .el-button--primary.is-plain:hover {
  color: #3f9eff !important;
}
::v-deep .wpg {
  width: auto;
}
::v-deep .ypg {
  width: auto;
}
::v-deep .el-breadcrumb {
  margin-left: 5px;
}
::v-deep .el-table__row {
  height: 100px;
}
::v-deep .el-table__row {
  height: 67px;
}
</style>